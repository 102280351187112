export const pedidos = {
  estados: [
    {
      label: 'BORRADOR',
      value: 'borrador',
      variant: 'secondary',
    },
    {
      label: 'PENDIENTE',
      value: 'pendiente',
      variant: 'warning',
    },
    {
      label: 'ACEPTADO',
      value: 'aceptado',
      variant: 'success',
    },
    {
      label: 'ENTREGADO',
      value: 'entregado',
      variant: 'info',
    },
    {
      label: 'CANCELADO',
      value: 'cancelado',
      variant: 'danger',
    },
    {
      label: 'ENVIADO',
      value: 'enviado',
      variant: 'success',
    },
  ],
}
